@import "./overrides/modal.scss";
@import "./overrides/dropdown.scss";
@import "./overrides/input.scss";
@import "./overrides/table.scss";
@import "./overrides/menu.scss";
@import "./overrides/layout.scss";
@import "./overrides/pagination.scss";
@import "./overrides/notification.scss";
@import "./overrides/tag.scss";
@import "./overrides/range-picker.scss";


.ant-alert .ant-alert-message {
  color: #FFFFFF;
}

.ant-btn-link:active {
  color: #ffffff55;
}

.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}

.anticon {
  color: inherit;
}

.ant-form .ant-form-item {
  margin-bottom: 16px;
}

.ant-checkbox-disabled + span {
  color: $primary-text-color;
  cursor: default;
}

.ant-btn:not(.ant-btn-circle) {
  line-height: inherit;
  border-radius: 4px;
}

.ant-btn-link {
  text-transform: uppercase;
}

.ant-tabs-tab {
  text-transform: uppercase;
}

.rc-virtual-list-scrollbar {
  display: block !important;
}
