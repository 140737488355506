@import "@bms/common-ui/src/scss/Common.scss";
@import "./Variables.scss";
@import "./AntDesignOverrides.scss";

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

.gm-tag {
  padding: 4px 15px;
  border-radius: 4px;
}

.main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: 500;
  }

  h1 {
    font-size: 1.17em;
  }
}

h1 {
  font-size: 1.17em;
}

svg {
  fill: currentColor;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-radius: 12px;
}

::-webkit-scrollbar-corner {
  background: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  width: 0;
  height: 0;
  background: #4e4e4e;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}
